<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
    slider: {
      type: Boolean,
      default: false,
    },
    sliceIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    textPosition() {
      if (this.sliceIndex % 2 === 0 || this.slider)
        return 'right'

      return 'left'
    },
    imageStyles() {
      const styles = { }

      if (this.$voix.breakpoint.value === 'default' || this.$voix.breakpoint.value === 'sm')
        styles.height = '600px'
      else
        styles.height = '80vh'

      return styles
    },
    cardStyles() {
      const styles = {}

      if (this.$voix.breakpoint.value === 'xl')
        styles.width = `${976}px`

      return styles
    },
  },
  mounted() {
    defineSlice({
      description: 'Hotel',
      fields: {
        title: { label: 'title', type: 'text' },
        logo: { label: 'logo', type: 'media', breakpoints: {
          default: { width: 700, height: 700 },
        } },
        magazineImage: {
          label: 'magazineImage',
          type: 'media',
          breakpoints: {
            default: { width: 1200, height: 700 },
          },
        },
        magazineTitle: { label: 'magazineTitle', type: 'text' },
        magazineDescription: { label: 'magazineDescription', type: 'text' },
        startingPrice: { label: 'startingPrice', type: 'text' },
        link: { label: 'link', type: 'text' },
      },
      name: { label: 'Hotel', group: 'Glue' },
      templates: [{
        label: 'Hotel',
        fields: {
          title: { value: 'Title' },
          logo: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Rooms/the-coral-logo.png' },
          magazineImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/glue/coral-pool.jpg' },
          magazineTitle: { value: 'Magazine Title' },
          magazineDescription: { value: 'Magazine Description' },
          startingPrice: { value: '125' },
          link: { value: { text: 'Link', href: '' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    v-if="fields.title.text !== 'Atlantis Marina'"
    class="relative w-full"
    :class="{ '-mt-24': !slider }"
  >
    <div class="xl:border-t-10 xl:border-white lg:pb-24">
      <VoixMedia
        v-if="fields.magazineImage.value"
        v-slot="slotProps" :field="fields.magazineImage" background
      >
        <ClientOnly>
          <div
            class="bg-cover bg-center bg-no-repeat xl:border-white"
            :style="{ backgroundImage: `url(${slotProps.image})`, ...imageStyles }"
          />
        </ClientOnly>
      </VoixMedia>

      <div
        v-if="!slider"
        class="mb-16 lg:mb-12 lg:absolute z-10 lg:bottom-0 bg-white py-10 px-12 flex flex-col lg:flex-row justify-between w-full"
        :class="{
          'lg:right-0': textPosition === 'right',
          'lg:left-0': textPosition !== 'right',
        }"
        :style="cardStyles"
      >
        <div
          class="pb-8 lg:pb-0 lg:pl-6 lg:pr-12 border-b lg:border-b-0 lg:border-r border-gray-400 flex flex-col lg:flex-row items-center lg:mr-12 mb-4 lg:mb-0"
        >
          <div>
            <VoixMedia
              v-if="fields.logo.value"
              :field="fields.logo" width="135"
              style="min-width: 135px; filter: grayscale(100%)"
            />
          </div>
        </div>
        <div class="flex flex-col lg:flex-row py-4">
          <div class="w-full justify-between flex">
            <div class="w-3/4 lg:pr-8">
              <div class="headline-2 text-zinc-700 mb-4">
                {{ fields.magazineTitle.text }}
              </div>
              <div
                v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm'"
                class="body text-zinc-600 mb-12 md:mb-0"
              >
                {{ fields.magazineDescription.text }}
              </div>
            </div>
            <div class="w-1/3 text-right flex flex-col justify-between">
              <div class="text-right mb-6">
                <!-- <div
                  class="font-sans text-zinc-700 font-bold tracking-wider text-lg"
                >
                  ${{ fields.startingPrice.text }}
                </div>
                <div
                  class="uppercase text-xs tracking-widest font-bold text-zinc-600 font-sans2"
                >
                  Starting
                </div> -->
              </div>
              <a
                v-if="
                  $voix.breakpoint !== 'default'
                    && $voix.breakpoint !== 'sm'
                    && fields.link.value
                "
                :href="fields.link.value.href"
                class="glue-btn text-xs tracking-wide bg-glueblue-700 text-white"
              >
                {{ fields.link.value.text }}
              </a>
            </div>
          </div>
          <template v-if="$voix.breakpoint === 'default' || $voix.breakpoint === 'sm'">
            <div class="body text-zinc-600 mb-12 md:mb-0">
              {{ fields.magazineDescription.text }}
            </div>
            <div class="w-full lg:w-2/5">
              <div class="flex justify-center lg:justify-end">
                <a
                  v-if="
                    $voix.breakpoint !== 'default'
                      && $voix.breakpoint !== 'sm'
                      && fields.link.value
                  "
                  :href="fields.link.value.href"
                  class="glue-btn btn-ghost border-zinc-600 text-xs tracking-widest p-3 pt-5 px-12 text-zinc-700"
                >
                  {{ fields.link.value.text }}
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
